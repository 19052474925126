// Imports
// ------
import styled from 'styled-components';
import { Section, Div, H2 } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)`
	padding: 12rem 0;
`;

export const Content = styled(Div)`
	h2 {
		font-size: 4rem;
	}

	h3 {
		margin-top: 1.75em;
		margin-bottom: 0.75em;
	}

	p, ol, ul, li {
		font-size: 2rem !important;
		font-weight: 300 !important;
		font-family: 'Work Sans','Helvetica',sans-serif;
		line-height: 1.25;
	}

	strong {
		font-weight: 600;
	}

	ol {
		margin-left: 1em;
		list-style: decimal;

		li {
			margin: 0.5em 0;
		}

		ol {
			list-style: lower-alpha;
		}
	}
`;

export const Title = styled(H2)`
	margin-bottom: 3.6rem;
`;
